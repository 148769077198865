<template>
    <div class="fill-height pa-4">
        <h1>Estrategia: RSI y Limit</h1>
        <br />
        <v-checkbox v-model="active" label="Activar RSI y Limit"></v-checkbox>
        <v-select :disabled="!active" :items="base_coins_list" label="Monedas Base" placeholder="ADA, XMR, LMN" outlined
            multiple v-model="base_coins"></v-select>
        <div class="d-flex">
            <v-select :disabled="!active" :items="quote_coins_list" class="mr-2" label="Monedas Quote"
                placeholder="BTC, ETH, USDT, BNB" outlined multiple v-model="quote_coins"></v-select>
            <v-text-field :disabled="!active" class="mr-2" label="Riesgo por operacion" placeholder="100" suffix="USDT"
                outlined v-model="risk_per_operation"></v-text-field>
            <v-select label="Exchange" v-model="exchange" :items="[{ text: 'Binance', value: 1 }]" outlined></v-select>
        </div>
        <div class="d-flex">
            <v-text-field :disabled="!active" label="RSI señal de compra" placeholder="35" outlined
                v-model="rsi_buy_limit"></v-text-field>
            <v-text-field class="ml-2" :disabled="!active" label="RSI rearme compra" placeholder="45" outlined
                v-model="rsi_rearm_rebuy"></v-text-field>
            <v-text-field class="ml-2" :disabled="!active" label="RSI señal de venta" placeholder="65" outlined
                v-model="rsi_sell_limit"></v-text-field>
            <v-text-field class="ml-2" :disabled="!active" label="RSI rearme venta" placeholder="55" outlined
                v-model="rsi_rearm_resell"></v-text-field>
        </div>
        <div class="d-flex">
            <FieldPercentage :disabled="!active" label="ROI de cierre" placeholder="0.5 %" suffix="%"
                v-model="min_profit" />
            <div class="mx-1"></div>
            <FieldPercentage :disabled="!active" label="ROI de cierre recompra" placeholder="2.5 %" suffix="%"
                v-model="min_profit_rebuy" />
            <div class="mx-1"></div>
            <FieldPercentage :disabled="!active" label="Stop Loss" placeholder="-1.0 %" suffix="%" v-model="stop_loss" />
            <div class="mx-1"></div>
            <FieldPercentage :disabled="!active" label="Recompra tras SL" placeholder="-2.0 %" suffix="%"
                v-model="rebuy_after_sl" />
            <v-text-field type="number" class="ml-2" :disabled="!active" label="Recompras máximas" placeholder="1" outlined
                v-model="max_rebuy_count"></v-text-field>
            <!-- <v-text-field
                type="number"
                class="ml-2"
                :disabled="!active"
                label="Operaciones máximas por par"
                placeholder="1"
                outlined
                v-model="max_operations_per_pair"
            ></v-text-field> -->
        </div>
        <v-row class="mx-0">
            <v-spacer></v-spacer>
            <v-btn @click="refresh_arbitrage_strategy" color="secondary">
                Restablecer
            </v-btn>
            <v-btn @click="patch_arbitrage_strategy" color="success" class="mx-2">
                Guardar
            </v-btn>
        </v-row>
        <v-row class="mx-0">
            <span>
                Blacklist
                {{
                    strategy.black_list.length == 0
                    ? ": vacía"
                    : ": " + strategy.black_list
                }}
            </span>
        </v-row>
        <v-row class="mx-0">
            <span>
                Active Symbols
                {{
                    strategy.active_symbols.length == 0
                    ? ": vacía"
                    : ": " + strategy.active_symbols
                }}
            </span>
        </v-row>
        <v-row class="ma-3">
            <v-btn @click="clean_active_and_bl"> FORZAR LIMPIEZA LISTAS </v-btn>
        </v-row>
    </div>
</template>

<script>
import coins from "@/utils/coins";
import FieldPercentage from "@/components/FieldPercentage";
export default {
    components: { FieldPercentage },
    data: () => ({
        base_coins_list: coins.base_coins_list,
        quote_coins_list: coins.quote_coins_list,

        base_coins: "",
        quote_coins: "",
        risk_per_operation: 0,
        rsi_buy_limit: 0,
        rsi_rearm_rebuy: 0,
        rsi_sell_limit: 0,
        rsi_rearm_resell: 0,
        min_profit: 0,
        min_profit_rebuy: 0,
        active: false,
        exchange: 1,
        stop_loss: 0,
        rebuy_after_sl: 0,
        max_rebuy_count: 1,
        max_operations_per_pair: 1,
    }),
    computed: {
        strategy() {
            return this.$store.state.bot.strategies.find(
                (st) => st.strategy == "rsi_wait"
            );
        },
    },
    watch: {
        strategy() {
            this.fillForm();
        },
    },
    methods: {
        clean_active_and_bl() {
            const st = {
                ...this.strategy,
                active_symbols: [],
                black_list: [],
            };
            this.$store.dispatch("bot/patchStrategy", st);
        },
        patch_arbitrage_strategy() {
            let st = {
                ...this.strategy,
                params: {
                    base_coins: this.base_coins,
                    quote_coins: this.quote_coins,
                    rsi_buy_limit: this.rsi_buy_limit,
                    rsi_rearm_rebuy: this.rsi_rearm_rebuy,
                    rsi_sell_limit: this.rsi_sell_limit,
                    rsi_rearm_resell: this.rsi_rearm_resell,
                    stop_loss: this.stop_loss,
                    rebuy_after_sl: this.rebuy_after_sl,
                    max_rebuy_count: this.max_rebuy_count,
                    max_operations_per_pair: this.max_operations_per_pair,
                    min_profit_rebuy: this.min_profit_rebuy,
                },
                risk_per_operation: this.risk_per_operation,
                min_profit: this.min_profit,
                active: this.active,
                exchange: this.exchange,
            };
            delete st["active_symbols"];
            delete st["black_list"];
            this.$store.dispatch("bot/patchStrategy", st);
        },
        refresh_arbitrage_strategy() {
            this.$store
                .dispatch("bot/loadStrategies")
                .then(() => this.fillForm());
        },
        fillForm() {
            if (this.strategy) {
                // Strategy JSON dict params
                this.base_coins = this.strategy.params.base_coins;
                this.quote_coins = this.strategy.params.quote_coins;
                this.rsi_buy_limit = this.strategy.params.rsi_buy_limit;
                this.rsi_rearm_rebuy = this.strategy.params.rsi_rearm_rebuy;
                this.rsi_sell_limit = this.strategy.params.rsi_sell_limit;
                this.rsi_rearm_resell = this.strategy.params.rsi_rearm_resell;
                this.stop_loss = this.strategy.params.stop_loss;
                this.rebuy_after_sl = this.strategy.params.rebuy_after_sl;
                this.max_rebuy_count = this.strategy.params.max_rebuy_count;
                this.max_operations_per_pair =
                    this.strategy.params.max_operations_per_pair;
                this.min_profit_rebuy = this.strategy.params.min_profit_rebuy;

                // native strategy fields
                this.risk_per_operation = this.strategy.risk_per_operation;
                this.min_profit = this.strategy.min_profit;
                this.active = this.strategy.active;
                this.exchange = this.strategy.exchange;
            }
        },
    },
    mounted() {
        this.fillForm();
    },
};
</script>
