<template>
    <v-col>
        <h2>Oportunidades</h2>
        <v-row>
            <v-col>
                <v-switch label="Ejecutar automáticamente" v-model="auto_mode">
                </v-switch>
            </v-col>
        </v-row>
        <v-row v-if="actual_oportunities.length == 0">
            <br />
            <v-col>
                <h3 class="red--text text--lighten-1">
                    No hay oportunidades con estos parámetros. Espere...
                </h3>
            </v-col>
        </v-row>
        <v-card class="ma-2" outlined v-for="op in actual_oportunities" :key="op.uuid">
            <v-card-title>
                <v-chip class="ma-1"> {{ op.q0 }} {{ op.q0_amount }} </v-chip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">
                            mdi-arrow-right-bold
                        </v-icon>
                    </template>
                    <span>
                        <strong>Trade 1</strong>
                    </span>
                </v-tooltip>
                <v-chip class="ma-1"> {{ op.base }} </v-chip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">
                            mdi-arrow-right-bold
                        </v-icon>
                    </template>
                    <span>
                        <strong>Trade 2</strong>
                    </span>
                </v-tooltip>
                <v-chip class="ma-1"> {{ op.q1 }} </v-chip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">
                            mdi-arrow-right-bold
                        </v-icon>
                    </template>
                    <span>
                        <strong>Trade 3</strong>
                    </span>
                </v-tooltip>
                <v-chip class="ma-1"> {{ op.q0 }} </v-chip>
                <v-spacer></v-spacer>
                <v-btn outlined @click="execute(op)"> EJECUTAR </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row class="ma-2">
                    <v-col>
                        <h3>Trade 1</h3>
                        {{ op.side1 }}: {{ op.symbol1 }} <br />
                        Comisión: {{ op.fee1 }} <br />
                        Precio Market: {{ op.price1 }} <br />
                    </v-col>
                    <v-col>
                        <h3>Trade 2</h3>
                        {{ op.side2 }}: {{ op.symbol2 }} <br />
                        Comisión: {{ op.fee2 }} <br />
                        Precio Market: {{ op.price2 }} <br />
                    </v-col>
                    <v-col>
                        <h3>Trade 3</h3>
                        {{ op.side3 }}: {{ op.symbol3 }} <br />
                        Comisión: {{ op.fee3 }} <br />
                        Precio Market: {{ op.price3 }} <br />
                    </v-col>
                    <v-col>
                        <h3>Beneficio</h3>
                        {{ op.profit_perc }}% ({{ op.profit.toPrecision(2) }})
                    </v-col>
                    <!-- <v-col>
                        Amounts: <br />
                        q0_amount: {{ op.q0_amount }} <br />
                        b_amount: {{ op.b_amount }} <br />
                        q1_amount: {{ op.q1_amount }} <br />
                        q0_final_amnt: {{ op.q0_final_amnt }} <br />
                    </v-col> -->
                    <v-spacer> </v-spacer>
                </v-row>
            </v-card-text>
            <v-card-actions> </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
import bus from "@/utils/event_bus";
import { v4 as uuidv4 } from "uuid";
import trade from "@/trade";
import * as Sentry from "@sentry/vue";
export default {
    props: ["strategy"],
    data: () => ({
        auto_mode: false,
        interval: null,
        interval2: null,
        possible_triangles: [],
        markets: {},
        bids_asks: {},
        ex: null,
    }),
    computed: {
        actual_oportunities() {
            let op_map = this.possible_triangles.map((tri) => {
                // SWAPS
                let swap_bq0 = false;
                let swap_bq1 = false;
                let swap_q0q1 = false;
                let q0b = tri.q0 + "/" + tri.base;
                let q1b = tri.q1 + "/" + tri.base;
                let q1q0 = tri.q1 + "/" + tri.q0;

                if (!(tri.bq0 in this.markets)) {
                    if (q0b in this.markets) {
                        swap_bq0 = true;
                    } else {
                        return false;
                    }
                }
                if (!(tri.bq1 in this.markets)) {
                    if (q1b in this.markets) {
                        swap_bq1 = true;
                    } else {
                        return false;
                    }
                }
                if (!(tri.q0q1 in this.markets)) {
                    if (q1q0 in this.markets) {
                        swap_q0q1 = true;
                    } else {
                        return false;
                    }
                }

                // BASICS
                let side1 = "BUY";
                let symbol1 = tri.bq0;
                let fee1 = this.markets[tri.bq0]?.taker;
                let price1 = this.bids_asks[tri.bq0]?.ask;
                let side2 = "SELL";
                let symbol2 = tri.bq1;
                let fee2 = this.markets[tri.bq1]?.taker;
                let price2 = this.bids_asks[tri.bq1]?.bid;
                let side3 = "BUY";
                let symbol3 = tri.q0q1;
                let fee3 = this.markets[tri.q0q1]?.taker;
                let price3 = this.bids_asks[tri.q0q1]?.ask;

                // SWAPT PARAMS
                if (swap_bq0) {
                    side1 = "SELL";
                    symbol1 = q0b;
                    fee1 = this.markets[q0b]?.taker;
                    price1 = this.bids_asks[q0b]?.bid;
                }
                if (swap_bq1) {
                    side2 = "BUY";
                    symbol2 = q1b;
                    fee2 = this.markets[q1b]?.taker;
                    price2 = this.bids_asks[q1b]?.ask;
                }
                if (swap_q0q1) {
                    side3 = "SELL";
                    symbol3 = q1q0;
                    fee3 = this.markets[q1q0]?.taker;
                    price3 = this.bids_asks[q1q0]?.bid;
                }

                // AMOUNTS ( TO BE FIXED WITH SWAPS AND SO ON )
                let q0_amount = 1000;
                let b_amount = (q0_amount / price1) * (1 - fee1);
                if (swap_bq0) b_amount = q0_amount * price1 * (1 - fee1);
                let q1_amount = b_amount * price2 * (1 - fee2);
                if (swap_bq1) q1_amount = (b_amount / price2) * (1 - fee2);
                let q0_final_amnt = (q1_amount / price3) * (1 - fee3);
                if (swap_q0q1) q0_final_amnt = q1_amount * price3 * (1 - fee3);
                let profit = (q0_final_amnt - q0_amount) / q0_amount;
                let profit_perc = (profit * 100).toPrecision(2);

                return {
                    ...tri,
                    uuid: uuidv4(),
                    side1,
                    symbol1,
                    fee1,
                    price1,
                    side2,
                    symbol2,
                    fee2,
                    price2,
                    side3,
                    symbol3,
                    fee3,
                    price3,
                    swap_bq0,
                    swap_bq1,
                    swap_q0q1,
                    profit,
                    profit_perc,
                };
            });
            let only_oportunities = op_map
                .filter((op) => !!op)
                .filter(
                    (op) =>
                        op.profit >= this.strategy.min_profit &&
                        op.profit != Infinity
                );
            let sorted_oportunities = only_oportunities.sort(
                (a, b) => b.profit - a.profit
            );
            return sorted_oportunities;
        },
        binance_auth() {
            return this.$store.state.bot.exchange_auths.find(() => true);
        },
    },
    watch: {
        binance_auth(ea) {
            this.ex.apiKey = ea.api_key;
            this.ex.secret = ea.secret_key;
        },
        actual_oportunities(ao) {
            if (this.auto_mode) {
                this.execute_all_ops(ao);
            }
        },
    },
    methods: {
        refresh_triangles() {
            bus.$emit("notification", {
                timeout: 2000,
                text: "Construyendo lista de posibles triángulos.",
                color: "info",
            });
            this.possible_triangles = trade.triangles(
                this.ex,
                this.strategy.params.base_coins,
                this.strategy.params.quote_coins
            );
        },
        async first_markets_fetch() {
            this.markets = await this.ex.load_markets();
        },
        async fetch_test() {
            this.bids_asks = await this.ex.fetch_bids_asks();
        },
        async execute_all_ops(ao) {
            for (let op of ao) {
                await this.execute(op);
            }
        },
        async execute(op) {
            console.log("EXECUTING");
            try {
                await trade.execute_op(
                    this.ex,
                    this.bids_asks,
                    op,
                    this.strategy.risk_per_operation
                );
                bus.$emit("notification", {
                    timeout: 2000,
                    text: "Triangulación completada.",
                    color: "info",
                });
            } catch (error) {
                bus.$emit("notification", {
                    timeout: 2000,
                    text: "Imposible completar la operacion! -> " + error,
                    color: "error",
                });
                Sentry.captureException(error, { extra: { op } });
            }
        },
    },
    mounted() {
        this.$store.dispatch("bot/loadExchangeAuths");
        this.ex = trade.get_ex("binance");
        this.first_markets_fetch();
        this.interval = setInterval(this.fetch_test, 10000);
        this.refresh_triangles();
        this.interval2 = setInterval(this.refresh_triangles, 180000);
    },
    beforeDestroy() {
        clearInterval(this.interval);
        clearInterval(this.interval2);
    },
};
</script>