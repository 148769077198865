<template>
    <div v-if="$store.state.session.authenticated" class="fill-height pa-4">
        <h1>Panel de control</h1>
        <div class="d-flex">
            <v-card class="ma-2">
                <v-card-title
                    >Balances Binance
                    <v-chip small outlined color="success" class="ml-3">
                        ACTIVO
                    </v-chip>
                </v-card-title>
                <v-card-text>
                    <p class="my-0" v-for="(v, k) in wallet" :key="k">
                        {{ v }} {{ k }}
                    </p>
                </v-card-text>
            </v-card>
            <v-card class="ma-2">
                <v-card-title> Account ROI </v-card-title>
                <v-card-text>
                    <!-- Diario/operaciones: {{ strategies_roi_d }} % <br /> -->
                    Diarios/capital:<br />
                    <p
                        class="my-0"
                        v-for="roi in daily_capital_rois"
                        :key="roi.coin+'_daily'"
                    >
                        {{ roi.coin }}: {{ roi.roi }} %
                    </p>
                    Semanales/capital:<br />
                    <p
                        class="my-0"
                        v-for="roi in weekly_capital_rois"
                        :key="roi.coin+'_weekly'"
                    >
                        {{ roi.coin }}: {{ roi.roi }} %
                    </p>
                </v-card-text>
            </v-card>
            <!-- <v-card class="ma-2">
                <v-card-title>ROI BBS </v-card-title>
                <v-card-text>
                    Diario: {{ strategy_bbs_roi_d }} % <br />Semanal:
                    {{ strategy_bbs_roi_w }} %
                </v-card-text>
            </v-card> -->
            <v-card class="ma-2">
                <v-card-title>
                    Estado del bot
                    <v-chip small outlined color="success" class="ml-3">
                        ACTIVO
                    </v-chip>
                </v-card-title>
                <v-card-text>
                    {{ strategies_running }} estrategia corriendo <br />{{
                        open_operations
                    }}
                    operaciones abiertas <br />{{
                        completed_operations
                    }}
                    operaciones completadas <br />{{ canceled_operations }}
                    operaciones canceladas
                </v-card-text>
            </v-card>
        </div>
        <br />
    </div>
    <div class="d-flex flex-column align-center" v-else>
        <br />
        <br />
        <v-icon size="120" color="grey">mdi-account</v-icon>
        <div class="display-1 mb-3">Sin autenticar</div>
        <div class="subheading mb-3">
            No estás logueado. Por favor, introduce tus credenciales para
            sincronizar con el servidor.
        </div>
        <br />
        <br />
        <v-btn x-large outlined color="orange darken-2" to="/login"
            >LOGIN</v-btn
        >
    </div>
</template>

<style scoped lang="scss">
// v-card {
//     @extend .ma-3 !optional;
// }
</style>

<script>
export default {
    name: "Dashboard",
    components: {},
    methods: {
        refresh_dashboard() {},
    },
    computed: {
        strategies_running() {
            return this.$store.state.bot.strategies.filter((s) => s.active)
                .length;
        },
        strategy_bbs() {
            return this.$store.state.bot.strategies.find(
                (st) => st.strategy == "bbs"
            );
        },
        strategy_tri() {
            return this.$store.state.bot.strategies.find(
                (st) => st.strategy == "triangular"
            );
        },
        strategy_rsi() {
            return this.$store.state.bot.strategies.find(
                (st) => st.strategy == "rsi_wait"
            );
        },
        open_operations() {
            return this.$store.state.bot.all_operations.filter((o) =>
                ["open", "error_retry", "no_funds_retry"].includes(o.status)
            ).length;
        },
        completed_operations() {
            return this.$store.state.bot.all_operations.filter(
                (o) => o.status == "completed"
            ).length;
        },
        canceled_operations() {
            return this.$store.state.bot.all_operations.filter(
                (o) => o.status == "canceled"
            ).length;
        },
        strategies_roi_d() {
            let roi = 0;
            let ops = this.$store.state.bot.all_operations.filter((op) => {
                let created = new Date(op.modified);
                let start = new Date();
                start.setHours(0, 0, 0);
                let end = new Date();
                end.setHours(23, 59, 59);
                return start < created && created < end;
            });
            ops.forEach((op) => {
                roi += op.metadata?.roi || 0;
            });
            return Math.round(roi * 10000) / 100;
        },
        daily_capital_rois() {
            let rois = [
                // { roi: 0.12, coin: "BTC" }
            ];
            let today = new Date();
            let yesterday = new Date(today.setDate(today.getDate() - 1));
            let last_midnight = yesterday.toISOString().split("T")[0];
            let old_wallet =
                this.$store.state.session.user_object.old_wallets?.find(
                    (ob) => ob.created.split("T")[0] == last_midnight
                );
            if (!old_wallet) return rois;
            Object.entries(this.wallet).forEach((item) => {
                let coin = item[0];
                let balance = item[1];
                let old_balance = old_wallet.balances[coin];
                let roi = (balance - old_balance) / old_balance;
                roi = Math.round(roi * 10000) / 100;
                rois.push({ roi, coin });
            });
            return rois;
        },
        weekly_capital_rois() {
            let rois = [
                // { roi: 0.12, coin: "BTC" }
            ];
            let today = new Date();
            let last_week = new Date(today.setDate(today.getDate() - 7));
            let last_week_midnight = last_week.toISOString().split("T")[0];
            let old_wallet =
                this.$store.state.session.user_object.old_wallets?.find(
                    (ob) => ob.created.split("T")[0] == last_week_midnight
                );
            if (!old_wallet) return rois;
            Object.entries(this.wallet).forEach((item) => {
                let coin = item[0];
                let balance = item[1];
                let old_balance = old_wallet.balances[coin];
                let roi = (balance - old_balance) / old_balance;
                roi = Math.round(roi * 10000) / 100;
                rois.push({ roi, coin });
            });
            return rois;
        },
        // strategies_roi_d_cap() {
        //     let roi = 0;
        //     let ops = this.$store.state.bot.all_operations.filter((op) => {
        //         let created = new Date(op.created);
        //         let start = new Date();
        //         start.setHours(0, 0, 0);
        //         let end = new Date();
        //         end.setHours(23, 59, 59);
        //         return start < created && created < end;
        //     });
        //     ops.forEach((op) => {
        //         roi += op.metadata?.roi || 0;
        //     });
        //     if (ops.length == 0) return 0;
        //     else {
        //         return Math.round(roi * 10000) / 100 / ops.length;
        //     }
        // },
        // strategy_rsi_roi_w_cap() {
        //     let roi = 0;
        //     let ops = this.$store.state.bot.all_operations.filter((op) => {
        //         let created = new Date(op.created);
        //         let start = new Date();
        //         start.setDate(start.getDate() - 7);
        //         start.setHours(0, 0, 0);
        //         let end = new Date();
        //         end.setHours(23, 59, 59);
        //         return start < created && created < end;
        //     });
        //     ops.forEach((op) => {
        //         roi += op.metadata?.roi || 0;
        //     });
        //     if (ops.length == 0) return 0;
        //     else {
        //         return Math.round(roi * 10000) / 100 / ops.length;
        //     }
        // },
        strategies_roi_w() {
            let roi = 0;
            let ops = this.$store.state.bot.all_operations.filter((op) => {
                let created = new Date(op.modified);
                let start = new Date();
                start.setDate(start.getDate() - 7);
                start.setHours(0, 0, 0);
                let end = new Date();
                end.setHours(23, 59, 59);
                return start < created && created < end;
            });
            ops.forEach((op) => {
                roi += op.metadata?.roi || 0;
            });
            return Math.round(roi * 10000) / 100;
        },
        strategy_bbs_roi_d() {
            return this.strategies_roi_d
        },
        strategy_bbs_roi_w() {
            return this.strategies_roi_w
        },
        wallet() {
            let wallet = this.$store.state.session.user_object.wallet;
            let filtered_wallet = {};
            Object.entries(wallet).forEach((item) => {
                let k = item[0];
                let v = item[1];
                if (v != 0) {
                    filtered_wallet[k] = v;
                }
            });
            return filtered_wallet;
        },
    },
    mounted() {
        this.$store.dispatch("bot/loadAllOperations");
    },
};
</script>
