let INITIAL_STATE = {}

if (process.env.NODE_ENV !== 'development') {
    // THIS COMES FROM DJANGO RENDERING spa.html TEMPLATE
    INITIAL_STATE = window.__INITIAL_STATE__
} else {
    INITIAL_STATE.VUE_APP_ROOT_API = 'http://localhost:8181/api/'
    INITIAL_STATE.PROD_ENV = 'dev'
}

export default INITIAL_STATE
