<template>
    <div>
        <v-btn icon @click.stop="onClickIcon">
            <v-icon> mdi-logout </v-icon>
        </v-btn>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
    name: "LoginDialog",
    data: () => ({}),
    props: {},
    computed: {
        authenticated() {
            return this.$store.state.session.authenticated;
        },
    },
    methods: {
        onClickIcon() {
            if (this.authenticated) {
                this.$store.commit("session/cleanSession");
                this.$router.push("/login");
            }
        },
    },
};
</script>
