<template>
    <v-container fluid class="fill-height align-space-around">
        <v-row justify="center">
            <v-col max-width="400" lg="3" class="ma-4">
                <v-form @keyup.enter.native="login">
                    <v-text-field
                        outlined
                        v-model="username"
                        autocomplete="username"
                        label="Login"
                        name="login"
                        append-icon="mdi-account"
                        type="text"
                        :error="error"
                    />
                    <v-text-field
                        outlined
                        v-model="password"
                        autocomplete="password"
                        id="password"
                        label="Password"
                        name="password"
                        append-icon="mdi-lock"
                        type="password"
                        :error="error"
                        :error-messages="
                            error ? 'Credenciales incorrectas' : ''
                        "
                    />
                    <v-row class="mx-0">
                        <v-spacer></v-spacer>
                        <v-btn
                            :loading="loading"
                            :disabled="loading"
                            color="seconday"
                            @click="login"
                        >
                            Login
                        </v-btn>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<style lang="scss" scoped>
</style>

<script>
import bus from "@/utils/event_bus";
export default {
    props: ["redirect_route"],
    components: {},
    data: () => ({
        loading: false,
        username: "",
        password: "",
        error: false,
    }),
    computed: {
        authenticated() {
            return this.$store.state.session.authenticated;
        },
        session_username() {
            return this.$store.state.session.user_object
                ? this.$store.state.session.user_object.username
                : "";
        },
    },
    watch: {
        username() {
            this.error = false;
        },
        password() {
            this.error = false;
        },
    },
    methods: {
        login() {
            this.loading = true;
            bus.$emit("notification", {
                timeout: 1000,
                text: "Trying to login",
                color: "info",
            });
            this.dialog = false;
            this.$store
                .dispatch("session/login", {
                    username: this.username,
                    password: this.password,
                })
                .then(() => {
                    // bus.$emit("notification", {
                    //     timeout: 1000,
                    //     text: "Logueado con éxito.",
                    //     color: "success",
                    // });
                    this.error = false;
                    this.loading = false;
                    this.redirect();
                })
                .catch((e) => {
                    if (e.response && e.response.status == 401) {
                        // bus.$emit("notification", {
                        //     timeout: 1000,
                        //     text: "Credenciales incorrectas.",
                        //     color: "warning",
                        // });
                        this.error = true;
                        this.loading = false;
                    } else {
                        // bus.$emit("notification", {
                        //     timeout: 3000,
                        //     text:
                        //         "No se puede entrar ahora mismo. Inténtalo de nuevo o contacta al admin.",
                        //     color: "error",
                        // });
                        this.loading = false;
                        throw e;
                    }
                });
        },
        redirect() {
            if (this.redirect_route) {
                console.log("redirecting!");
                this.$router.push(this.redirect_route);
            } else {
                this.$router.push("dashboard");
            }
        },
    },
    mounted() {
        if (this.authenticated) this.redirect();
    },
};
</script>