import { backend_api, paginated_factory } from '@/utils/api'
// import bus from '@/utils/event_bus'

export default {
    namespaced: true,
    state: {
        operations: [],
        all_operations: [], // Including old ones
        strategies: [],
        trades: [],
        exchange_auths: [],
    },
    mutations: {
        setOperations: (state, data) => state.operations = data,
        setAllOperations: (state, data) => state.all_operations = data,
        setOperation: (state, data) => {
            let operations = [...state.operations]
            let i = state.operations.findIndex(p => p.uuid == data.uuid)
            if (i != -1) operations[i] = { ...data }
            else operations.push(data)
            state.operations = operations
        },
        createOperation: (state, data) => state.operations = [...state.operations, data],
        setStrategies: (state, data) => state.strategies = data,
        setTrades: (state, data) => state.trades = data,
        createTrade: (state, data) => state.trades.push(data),
        setExchangeAuths: (state, data) => state.exchange_auths = data,
        setStrategy: (state, data) => {
            let strategies = [...state.strategies]
            let i = state.strategies.findIndex(p => p.uuid == data.uuid)
            if (i != -1) strategies[i] = { ...data }
            else strategies.push(data)
            state.strategies = strategies
        },
        setExchangeAuth: (state, data) => {
            let exchange_auths = [...state.exchange_auths]
            let i = state.exchange_auths.findIndex(p => p.uuid == data.uuid)
            if (i != -1) exchange_auths[i] = { ...data }
            else exchange_auths.push(data)
            state.exchange_auths = exchange_auths
        },
    },
    actions: {
        loadOperations(context) {
            return paginated_factory('/operations/open/')()
                .then((data) => {
                    context.commit('setOperations', data)
                })
                .catch(e => console.log(`Cant get operations: ${e}`))
        },
        loadAllOperations(context) {
            return paginated_factory('/operations/')()
                .then((data) => {
                    context.commit('setAllOperations', data)
                })
                .catch(e => console.log(`Cant get operations: ${e}`))
        },
        patchOperation(context, payload) {
            let op_uuid = payload.uuid
            return backend_api.put('operations/' + op_uuid + '/', payload)
                .then(({ data }) => {
                    context.commit('setOperation', data)
                })
                .catch(e => console.log(`Cant patch operation: ${e}`))
        },
        postOperation(context, payload) {
            console.log(context.state.strategies.find(str => str.strategy == "trimanual"))
            if (!payload.strategy) {
                payload.strategy = context.state.strategies.find(str => str.strategy == "trimanual")?.id
            }
            return backend_api.post('operations/', payload)
                .then(({ data }) => {
                    context.commit('createOperation', data)
                })
                .catch(e => console.log(`Cant create operation registry: ${e}`))
        },
        loadStrategies(context) {
            return paginated_factory('/strategies/')()
                .then((data) => {
                    context.commit('setStrategies', data)
                })
                .catch(e => console.log(`Cant get strategies: ${e}`))
        },
        loadTrades(context) {
            return paginated_factory('/trades/')()
                .then((data) => {
                    context.commit('setTrades', data)
                })
                .catch(e => console.log(`Cant get trades: ${e}`))
        },
        postTrade(context, payload) {
            return backend_api.post('trades/', payload)
                .then(({ data }) => {
                    context.commit('createTrade', data)
                })
                .catch(e => console.log(`Cant create trade registry: ${e}`))
        },
        loadExchangeAuths(context) {
            return paginated_factory('/exchange_auths/')()
                .then((data) => {
                    context.commit('setExchangeAuths', data)
                })
                .catch(e => console.log(`Cant get exchange_auths: ${e}`))
        },
        patchStrategy(context, payload) {
            let strategy_uuid = payload.uuid
            return backend_api.put('strategies/' + strategy_uuid + '/', payload)
                .then(({ data }) => {
                    context.commit('setStrategy', data)
                })
                .catch(e => console.log(`Cant patch strategy: ${e}`))
        },
        patchExchangeAuth(context, payload) {
            let exchange_auth_uuid = payload.uuid
            return backend_api.put('exchange_auths/' + exchange_auth_uuid + '/', payload)
                .then(({ data }) => {
                    context.commit('setExchangeAuth', data)
                })
                .catch(e => console.log(`Cant patch exchange_auth: ${e}`))
        },
    },
}