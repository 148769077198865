<template>
    <v-text-field :disabled="disabled" :label="label" :placeholder="placeholder" :suffix="suffix" outlined
        v-model="internal"></v-text-field>
</template>

<script>
export default {
    props: ["value", "disabled", "label", "placeholder", "suffix"],
    data: () => ({ internal: "" }),
    watch: {
        value(val) {
            if (val == "-" || val == "") this.internal = val;
            else this.internal = Math.round(val * 10000) / 100;
        },
        internal(val) {
            if (val == "-" || val == "") this.$emit("input", val);
            else {
                let format_val = Math.round(val * 100) / 10000;
                this.$emit("input", format_val);
            }
        },
    },
    mounted() {
        if (this.value == "-") this.internal = this.value;
        else this.internal = Math.round(this.value * 10000) / 100;
    }
};
</script>