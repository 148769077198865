<template>
    <v-app id="inspire">
        <v-navigation-drawer v-if="$route.name != 'login'" v-model="drawer" app clipped>
            <v-list dense>
                <v-list-item link to="/">
                    <v-list-item-action>
                        <v-icon>mdi-view-dashboard</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Panel de control</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link to="/operations">
                    <v-list-item-action>
                        <v-icon>mdi-bank-transfer</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Operaciones activas</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!-- <v-list-item link to="/strategy/triangular">
                  <v-list-item-action>
                      <v-icon>mdi-cog-transfer-outline</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                      <v-list-item-title
                          >Arbi-Tri Auto</v-list-item-title
                      >
                  </v-list-item-content>
              </v-list-item> -->
                <!-- <v-list-item link to="/strategy/trimanual">
                  <v-list-item-action>
                      <v-icon>mdi-cog-transfer-outline</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                      <v-list-item-title
                          >Arbi-Tri Manual</v-list-item-title
                      >
                  </v-list-item-content>
              </v-list-item> -->
                <!-- <v-list-item link to="/strategy/rsi">
                  <v-list-item-action>
                      <v-icon>mdi-cog-transfer-outline</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                      <v-list-item-title>RSI-Limit</v-list-item-title>
                  </v-list-item-content>
              </v-list-item> -->
                <v-list-item link to="/strategy/bbs">
                    <v-list-item-action>
                        <v-icon>mdi-cog-transfer-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>BBS Market</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link to="/history">
                    <v-list-item-action>
                        <v-icon>mdi-format-list-numbered</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Registro de operaciones</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link to="/tutorials">
                    <v-list-item-action>
                        <v-icon>mdi-school</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title> Tutorials </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link to="/disclaimers">
                    <v-list-item-action>
                        <v-icon> mdi-file-certificate </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title> Disclaimers </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app clipped-left color="orange darken-2">
            <v-app-bar-nav-icon v-if="$route.name != 'login'" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <img id="logo" alt="logo" src="./assets/logo.png" style="margin-left: 5px; height: 50px" />
            <!-- <v-toolbar-title class="ml-2">Crypto Bot</v-toolbar-title> -->
            <v-spacer></v-spacer>

            <v-btn v-if="$route.name != 'login'" icon to="/settings">
                <v-icon>mdi-cog</v-icon>
            </v-btn>
            <v-btn v-if="$route.name != 'login'" icon @click="loadAll()">
                <v-icon>mdi-sync</v-icon>
            </v-btn>
            <LoginDialog v-if="$route.name != 'login'" />
        </v-app-bar>

        <v-main>
            <router-view></router-view>
            <Notifications />
        </v-main>

        <!-- <v-footer app>
          <span>-- info de contexto aquí --</span>
      </v-footer>-->
    </v-app>
</template>

<script>
import LoginDialog from "@/components/LoginDialog.vue";
import Notifications from "@/components/Notifications.vue";
export default {
    components: {
        Notifications,
        LoginDialog,
    },
    data: () => ({
        drawer: null,
        interval: null,
    }),
    methods: {
        loadAll() {
            this.$store.dispatch("loadAll");
        },
        silentRefresh() {
            this.$store.dispatch("silentRefresh");
        },
    },
    created() {
        this.$vuetify.theme.dark = true;
    },
    mounted() {
        this.loadAll();
        // Refresh each 30 seconds
        this.interval = setInterval(this.silentRefresh, 60000);
    },
    beforeDestroy() {
        clearInterval(this.interval);
    },
};
</script>