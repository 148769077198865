import { render, staticRenderFns } from "./Dashboard.vue?vue&type=template&id=dc65fb46&scoped=true"
import script from "./Dashboard.vue?vue&type=script&lang=js"
export * from "./Dashboard.vue?vue&type=script&lang=js"
import style0 from "./Dashboard.vue?vue&type=style&index=0&id=dc65fb46&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc65fb46",
  null
  
)

export default component.exports