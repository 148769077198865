import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Integrations } from "@sentry/tracing";
import INITIAL_STATE from '@/utils/defaults';
import * as Sentry from "@sentry/vue";
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    Vue,
    dsn: "https://766cd39680784dc19bdc0fe0df07a67b@o146079.ingest.sentry.io/5825282",
    integrations: [new Integrations.BrowserTracing()],
    release: "bilbao-front@" + process.env.npm_package_version,
    tracesSampleRate: 0.2,
    environment: INITIAL_STATE.PROD_ENV,
  });
}


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
