import Vue from 'vue'
import Vuex from 'vuex'
import bus from '@/utils/event_bus'
import botModule from './bot'
import sessionModule from './session'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        silent_refreshing: false
    },
    mutations: {
        lockSilentRefresh: state => state.silent_refreshing = true,
        unlockSilentRefresh: state => state.silent_refreshing = false,
    },
    actions: {
        silentRefresh(context) {
            context.commit("lockSilentRefresh")
            Promise.all([
                context.dispatch('session/getUserObject'),
                context.dispatch("bot/loadOperations"),
                context.dispatch("bot/loadStrategies"),
                // context.dispatch("bot/loadTrades"),
            ]).then(() => {
                console.log("silent refresh done")
                context.commit("unlockSilentRefresh")
            }).catch(() => {
                context.commit("unlockSilentRefresh")
            })
        },
        loadAll(context) {
            bus.$emit("notification", {
                timeout: 1000,
                text: "Cargando datos del servidor",
                color: "info"
            });
            context.commit("lockSilentRefresh")
            Promise.all([
                context.dispatch('session/getUserObject'),
                context.dispatch("bot/loadOperations"),
                context.dispatch("bot/loadStrategies"),
                // context.dispatch("bot/loadTrades"),
                context.dispatch("bot/loadExchangeAuths"),
            ]).then(() => {
                bus.$emit("notification", {
                    timeout: 1000,
                    text: "Listo",
                    color: "success"
                });
                context.commit("unlockSilentRefresh")
            }).catch(() => {
                bus.$emit("notification", {
                    timeout: 1000,
                    text: "Problema en la comunicación",
                    color: "warning"
                });
                context.commit("unlockSilentRefresh")
            })
        },
    },
    modules: {
        bot: botModule,
        session: sessionModule,
    }
})
