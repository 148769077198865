<template>
    <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                :disabled="loading"
                :loading="loading"
                v-bind="attrs"
                v-on="on"
                class="ml-1"
                outlined
                color="warning"
                small
            >
                <v-icon small> mdi-delete </v-icon> FORZAR CIERRE
            </v-btn>
        </template>

        <v-card>
            <v-card-title> Forzar cierre </v-card-title>
            <v-card-text>
                Este cierre forzoso no implica ninguna operación en el exchange.
                La operación será marcada como cerrada en la base de datos del
                bot.
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    :disabled="loading"
                    :loading="loading"
                    @click="forceClose"
                    class="ml-1"
                    color="error"
                    outlined
                    small
                >
                    <v-icon left small>mdi-delete</v-icon>
                    FORZAR CIERRE
                </v-btn>
                <v-btn
                    @click="dialog = false"
                    class="ml-1"
                    color="primary"
                    small
                    outlined
                >
                    CANCELAR
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
export default {
    props: ["operation"],
    data: () => ({ dialog: false, loading: false }),
    computed: {},
    methods: {
        forceClose() {
            this.loading = true;
            this.dialog = false;
            let new_operation = {
                ...this.operation,
                status: "canceled",
            };
            this.$store
                .dispatch("bot/patchOperation", new_operation)
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>