export default {
    base_coins_list: [
        "AAVE",
        "ACA",
        "ACH",
        "ACM",
        "ADA",
        "ADX",
        "AERGO",
        "AGIX",
        "AGLD",
        "AKRO",
        "ALCX",
        "ALGO",
        "ALICE",
        "ALPACA",
        "ALPHA",
        "ALPINE",
        "AMB",
        "AMP",
        "ANKR",
        "ANT",
        "APE",
        "API3",
        "APT",
        "AR",
        "ARB",
        "ARDR",
        "ARK",
        "ARKM",
        "ARPA",
        "ARS",
        "ASR",
        "AST",
        "ASTR",
        "ATA",
        "ATM",
        "ATOM",
        "AUCTION",
        "AUDIO",
        "AVA",
        "AVAX",
        "AXS",
        "BADGER",
        "BAKE",
        "BAL",
        "BAND",
        "BAR",
        "BAT",
        "BCH",
        "BEL",
        "BETA",
        "BETH",
        "BICO",
        "BIDR",
        "BIFI",
        "BLZ",
        "BNB",
        "BNT",
        "BNX",
        "BOND",
        "BRL",
        "BSW",
        "BTC",
        "BTS",
        "BTTC",
        "BUSD",
        "C98",
        "CAKE",
        "CELO",
        "CELR",
        "CFX",
        "CHESS",
        "CHR",
        "CHZ",
        "CITY",
        "CKB",
        "CLV",
        "COMBO",
        "COMP",
        "COS",
        "COTI",
        "CREAM",
        "CRV",
        "CTK",
        "CTXC",
        "CVC",
        "CVP",
        "CVX",
        "CYBER",
        "DAI",
        "DAR",
        "DASH",
        "DATA",
        "DCR",
        "DEGO",
        "DENT",
        "DEXE",
        "DF",
        "DGB",
        "DIA",
        "DOCK",
        "DODO",
        "DOGE",
        "DOT",
        "DREP",
        "DUSK",
        "DYDX",
        "EDU",
        "EGLD",
        "ELF",
        "ENJ",
        "ENS",
        "EOS",
        "EPX",
        "ERN",
        "ETC",
        "ETH",
        "EUR",
        "FARM",
        "FDUSD",
        "FET",
        "FIDA",
        "FIL",
        "FIO",
        "FIS",
        "FLM",
        "FLOKI",
        "FLOW",
        "FLIX",
        "FOR",
        "FOTH",
        "FRONT",
        "FTM",
        "FTT",
        "FUN",
        "FXS",
        "GAL",
        "GALA",
        "GAS",
        "GBP",
        "GFT",
        "GHST",
        "GLM",
        "GLMR",
        "GMT",
        "GMX",
        "GNO",
        "GNS",
        "GRT",
        "GTC",
        "HARD",
        "HBAR",
        "HFT",
        "HIFI",
        "HIGH",
        "HIVE",
        "HOOK",
        "HOT",
        "1INCH",
        "ICP",
        "ICX",
        "ID",
        "IDEX",
        "IDRT",
        "ILV",
        "IMX",
        "INJ",
        "IOST",
        "IOTA",
        "IOTX",
        "IQ",
        "IRIS",
        "JASMY",
        "JOE",
        "JUST",
        "JUV",
        "KAVA",
        "KDA",
        "KEY",
        "KLAY",
        "KMD",
        "KNC",
        "KP3R",
        "KSM",
        "LAZIO",
        "LDO",
        "LEVER",
        "LINA",
        "LINK",
        "LIT",
        "LOKA",
        "LOOM",
        "LPT",
        "LQTY",
        "LRC",
        "LSK",
        "LTC",
        "LTO",
        "LUNA",
        "LUNC",
        "MAGIC",
        "MANA",
        "MASK",
        "MATIC",
        "MAV",
        "MBL",
        "MBOX",
        "MC",
        "MDT",
        "MDX",
        "MINA",
        "MKR",
        "MLN",
        "MOVR",
        "MTL",
        "MULTI",
        "NEAR",
        "NEO",
        "NEXO",
        "NGN",
        "NKN",
        "NMR",
        "NULS",
        "OAX",
        "OCEAN",
        "OG",
        "OGN",
        "OM",
        "OMG",
        "ONE",
        "ONG",
        "ONT",
        "OOKI",
        "OP",
        "ORN",
        "OSMO",
        "OXT",
        "PAXG",
        "PENDLE",
        "PEOPLE",
        "PEPE",
        "PERL",
        "PERP",
        "PHA",
        "PHB",
        "PIVX",
        "PLA",
        "PLN",
        "PNT",
        "POLS",
        "POLYX",
        "POND",
        "PORTO",
        "POWR",
        "PROM",
        "PROS",
        "PSG",
        "PUNDIX",
        "PYR",
        "QI",
        "QKC",
        "QNT",
        "QTUM",
        "QUICK",
        "RAD",
        "RARE",
        "RAY",
        "RDNT",
        "REEF",
        "REY",
        "REN",
        "REQ",
        "RIF",
        "RLC",
        "RNDR",
        "RON",
        "ROSE",
        "RPL",
        "RSR",
        "RUNE",
        "RVN",
        "SAND",
        "SANTOS",
        "SC",
        "SCRT",
        "SEI",
        "SFP",
        "SHIB",
        "SKL",
        "SLP",
        "SNT",
        "SNX",
        "SOL",
        "SPELL",
        "SSV",
        "STEEM",
        "STG",
        "STMX",
        "STORJ",
        "STPT",
        "STRAX",
        "STX",
        "SUI",
        "SUN",
        "SUPER",
        "SUSHI",
        "SXP",
        "SYN",
        "SYS",
        "T",
        "TFUEL",
        "THETA",
        "TKO",
        "TLM",
        "TOMO",
        "TORN",
        "TRB",
        "TROY",
        "TRU",
        "TRX",
        "TRY",
        "TUSD",
        "TVK",
        "TWT",
        "UAH",
        "UFT",
        "UMA",
        "UNFI",
        "UNI",
        "USDC",
        "USDP",
        "USDT",
        "USTC",
        "UTK",
        "VAI",
        "VET",
        "VGX",
        "VIB",
        "VIDT",
        "VITE",
        "VOXEL",
        "VTHO",
        "WAN",
        "WAVES",
        "WAXP",
        "WBETH",
        "WBTC",
        "WIN",
        "WING",
        "WLD",
        "WNXM",
        "WOO",
        "WRX",
        "WTC",
        "XEC",
        "XEM",
        "XLM",
        "XNO",
        "XRP",
        "XTZ",
        "XVG",
        "XVS",
        "YFI",
        "YGG",
        "ZAR",
        "ZEC",
        "ZIL",
        "ZRX",
    ],

    quote_coins_list: [
        "BUSD",
        "EUR",
        "GBP",
        "USDC",
        "USDT",
    ]
}