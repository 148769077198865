<template>
    <div class="fill-height pa-4">
        <h1>Disclaimers</h1>
        <div class="d-flex flex-wrap">
            <v-card :href="disclaimer.asset" target="_blank" width="500" class="ma-3" v-for="disclaimer in disclaimers"
                :key="disclaimer.id">
                <v-img :src="require('@/assets/' + disclaimer.picture)" aspect-ratio="0.8"></v-img>
                <v-card-text> {{ disclaimer.text }} </v-card-text>
                <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :href="disclaimer.asset" target="_blank">OPEN</v-btn>
                </v-card-actions> -->
            </v-card>
        </div>
    </div>
</template>

<script>
export default {
    name: "Disclaimers",
    components: {},
    data: () => ({
        disclaimers: [
            { "id": 1, "text": "Disclaimer AI Crypto Bot - Español", "picture": "disc_es_mini.png", "asset": "/static/pdfs/disclaimer_es.pdf" },
            { "id": 2, "text": "AI Crypto Bot Disclaimer - English", "picture": "disc_en_mini.png", "asset": "/static/pdfs/disclaimer_en.pdf" }
        ]
    }),
    computed: {
    },
    methods: {},
};
</script>
