import axios from 'axios'
import defaults from '@/utils/defaults'
// import bus from '@/utils/event_bus'
import router from '@/router'
import store from '@/store'

console.log("[i] Launching app. Using this root api URL: " + defaults.VUE_APP_ROOT_API)




const backend_api = axios.create({
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken',
    baseURL: defaults.VUE_APP_ROOT_API,
    validateStatus: function (status) {
        return status >= 200 && status < 300; // default
    },
});
backend_api.interceptors.request.use(config => {
    if (store.state.session.authenticated) {
        config['headers']['Authorization'] = 'Bearer ' + store.state.session.access_token
    } else {
        config['headers']['Authorization'] = 'NO_AUTH'
    }
    // bus.$emit('request_increment')
    return config
})
backend_api.interceptors.response.use(
    response => {
        //         bus.$emit('request_decrement')
        return response
    },
    error => {
        if (error.response && error.response.status == 403) {
            // bus.$emit('notification', { title: "EXPIRED SESSION", text: "Redirecting to login page " + error.message, color: "error" })
            // bus.$emit("notification", {
            //     timeout: 3000,
            //     text: "Session expired, please login.",
            //     color: "warning"
            // });
            router.push("/login").catch(() => console.log("[!] Can't go to login. Maybe already in login page."))
            // setTimeout(bus.$emit("launch_login"), 5000)
            
        }
        return Promise.reject(error)
    }
)


// returns a complete promise
function paginated_factory(url) {
    return function paginatedFetcher(next, obj_list = []) {
        return backend_api.get(next ? next : url)
            .then(({ data }) => {
                obj_list.push(...data.results)
                if (!data.next) return obj_list
                return paginatedFetcher(data.next, obj_list)
            })
    }
}





export {
    backend_api,
    paginated_factory
};

// LOGOUT FROM SESSION !!!!!!
// axios.get(defaults.VUE_APP_LOGOUT_URL)
//     .then(console.log("Cleaned session"))



// LOGOUT FROM SESSION !!!!!!
axios.get(defaults.VUE_APP_ROOT_API + 'auth/logout/')
    .then(console.log("Cleaned session"))