import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Dashboard from '@/views/Dashboard.vue'
import Login from '@/views/Login.vue'
import History from '@/views/History.vue'
import Operations from '@/views/Operations.vue'
import StrategyTri from '@/views/StrategyTri.vue'
import StrategyTriManual from '@/views/StrategyTriManual.vue'
import StrategyRSI from '@/views/StrategyRSI.vue'
import StrategyBBS from '@/views/StrategyBBS.vue'
import Settings from '@/views/Settings.vue'
import Tutorials from '@/views/Tutorials.vue'
import Disclaimers from '@/views/Disclaimers.vue'

Vue.use(VueRouter)



const isAuthenticated = function (to, from, next) {
    if (!store.state.session.authenticated) {
        next('/login')
    } else if (store.state.session.user_object._flag == "not-initialized") {
        store.dispatch('session/getUserObject')
            .then(() => {
                next()
            })
    } else {
        next()
    }
}

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        beforeEnter: isAuthenticated
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/history',
        name: 'history',
        component: History,
        beforeEnter: isAuthenticated
    },
    {
        path: '/operations',
        name: 'operations',
        component: Operations,
        beforeEnter: isAuthenticated
    },
    {
        path: '/strategy/triangular',
        name: 'strategy_triangular',
        component: StrategyTri,
        beforeEnter: isAuthenticated
    },
    {
        path: '/strategy/trimanual',
        name: 'strategy_triangular_manual',
        component: StrategyTriManual,
        beforeEnter: isAuthenticated
    },
    {
        path: '/strategy/rsi',
        name: 'strategy_rsi',
        component: StrategyRSI,
        beforeEnter: isAuthenticated
    },
    {
        path: '/strategy/bbs',
        name: 'strategy_bbs',
        component: StrategyBBS,
        beforeEnter: isAuthenticated
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        beforeEnter: isAuthenticated
    },
    {
        path: '/tutorials',
        name: 'tutorials',
        component: Tutorials,
        beforeEnter: isAuthenticated
    },
    {
        path: '/disclaimers',
        name: 'disclaimers',
        component: Disclaimers,
        beforeEnter: isAuthenticated
    },
]

const router = new VueRouter({
    mode: 'history',
    base: '/app/',
    routes
})






export default router
