<template>
    <div class="fill-height pa-4">
        <h1>Estrategia: BBS</h1>
        <br />
        <v-checkbox v-model="active" label="Activar BBS"></v-checkbox>
        <v-select :disabled="!active" :items="base_coins_list" label="Monedas Base" placeholder="ADA, XMR, LMN" outlined
            multiple v-model="base_coins"></v-select>
        <div class="d-flex">
            <v-select :disabled="!active" :items="quote_coins_list" class="mr-2" label="Monedas Quote"
                placeholder="BTC, ETH, USDT, BNB" outlined multiple v-model="quote_coins"></v-select>
            <v-text-field :disabled="!active" class="mr-2" label="Riesgo por operacion" placeholder="100" suffix="USDT"
                outlined v-model="risk_per_operation"></v-text-field>
            <v-select label="Exchange" v-model="exchange" :items="[{ text: 'Binance', value: 1 }]" outlined></v-select>
        </div>
        <div class="d-flex">
            <!-- <v-text-field :disabled="!active" label="RSI señal de compra" placeholder="35" outlined
                v-model="rsi_buy_limit"></v-text-field>
            <v-text-field class="ml-2" :disabled="!active" label="RSI rearme compra" placeholder="45" outlined
                v-model="rsi_rearm_rebuy"></v-text-field>
            <v-text-field class="ml-2" :disabled="!active" label="RSI señal de venta" placeholder="65" outlined
                v-model="rsi_sell_limit"></v-text-field>
            <v-text-field class="ml-2" :disabled="!active" label="RSI rearme venta" placeholder="55" outlined
                v-model="rsi_rearm_resell"></v-text-field> -->
        </div>
        <div class="d-flex">

            <!--
            <div class="mx-1"></div>
            <FieldPercentage :disabled="!active" label="ROI de cierre recompra" placeholder="2.5 %" suffix="%"
                v-model="min_profit_rebuy" />
            <div class="mx-1"></div>
            <FieldPercentage :disabled="!active" label="Stop Loss" placeholder="-1.0 %" suffix="%" v-model="stop_loss" />
            <div class="mx-1"></div>
            <FieldPercentage :disabled="!active" label="Recompra tras SL" placeholder="-2.0 %" suffix="%"
                v-model="rebuy_after_sl" />
            <v-text-field type="number" class="ml-2" :disabled="!active" label="Recompras máximas" placeholder="1" outlined
                v-model="max_rebuy_count"></v-text-field> -->
            <!-- <v-text-field
                type="number"
                class="ml-2"
                :disabled="!active"
                label="Operaciones máximas por par"
                placeholder="1"
                outlined
                v-model="max_operations_per_pair"
            ></v-text-field> -->
        </div>
        <v-row class="ma-10">
            <v-expansion-panels multiple>
                <v-expansion-panel>
                    <v-expansion-panel-header>Blacklist</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row class="mx-0">
                            <span>
                                Blacklist
                                {{
            strategy.black_list.length == 0
                ? ": vacía"
                : ": " + strategy.black_list
        }}
                            </span>
                        </v-row>
                        <v-row class="mx-0">
                            <span>
                                Active Symbols
                                {{
                strategy.active_symbols.length == 0
                    ? ": vacía"
                    : ": " + strategy.active_symbols
            }}
                            </span>
                        </v-row>
                        <v-row class="ma-3">
                            <v-btn @click="clean_bl" class="ma-2"> LIMPIAR BLACK LIST </v-btn>
                            <br>
                            <v-btn @click="clean_active" class="ma-2"> LIMPIAR ACTIVOS </v-btn>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>Advanced Setup</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-alert dense outlined type="error">
                            Cuidado. Esto es para usuarios <strong>avanzados</strong>. Deja el formulario en blanco para
                            que
                            el sistema use los valores por defecto.
                        </v-alert>
                        <v-container>
                            <v-row>
                                <v-col>
                                    <FieldPercentage :disabled="!active" label="Stop Loss MODO A" placeholder="-1.5 %"
                                        suffix="%" v-model="stop_loss_a" />
                                </v-col>
                                <v-col>
                                    <FieldPercentage :disabled="!active" label="Stop Loss MODO B" placeholder="-0.5 %"
                                        suffix="%" v-model="stop_loss_b" />
                                </v-col>
                                <v-col>
                                    <FieldPercentage :disabled="!active" label="ROI alto" placeholder="0.17 %"
                                        suffix="%" v-model="min_profit_high" />
                                </v-col>
                                <v-col>
                                    <FieldPercentage :disabled="!active" label="ROI bajo" placeholder="0.17 %"
                                        suffix="%" v-model="min_profit_low" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field :disabled="!active" class="mr-2" label="bb_window" placeholder="20"
                                        outlined v-model="bb_window"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field :disabled="!active" class="mr-2" label="bb_window_dev" placeholder="2"
                                        outlined v-model="bb_window_dev"></v-text-field>
                                </v-col>
                                <v-col>
                                    <FieldPercentage :disabled="!active" label="bullish_gain" placeholder="0.12 %"
                                        suffix="%" v-model="bullish_gain" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field :disabled="!active" class="mr-2" label="stoch_window" placeholder="14"
                                        outlined v-model="stoch_window"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field :disabled="!active" class="mr-2" label="stoch_smooth1" placeholder="3"
                                        outlined v-model="stoch_smooth1"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field :disabled="!active" class="mr-2" label="stoch_smooth2" placeholder="3"
                                        outlined v-model="stoch_smooth2"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field :disabled="!active" class="mr-2" label="stoch_lower_limit"
                                        placeholder="25" outlined v-model="stoch_lower_limit"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field :disabled="!active" class="mr-2" label="stoch_cross_validation_limit"
                                        placeholder="25" outlined v-model="stoch_cross_validation_limit"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field :disabled="!active" class="mr-2" label="stoch_upper_limit"
                                        placeholder="95" outlined v-model="stoch_upper_limit"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field :disabled="!active" class="mr-2" label="stoch_upper_limit_sell"
                                        placeholder="80" outlined v-model="stoch_upper_limit_sell"></v-text-field>
                                </v-col>
                                <v-col>
                                    <FieldPercentage :disabled="!active" label="threshold_24h_gain" placeholder="1.5 %"
                                        suffix="%" v-model="threshold_24h_gain" />
                                </v-col>
                            </v-row>
                        </v-container>

                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
        <v-row class="mx-0">
            <v-spacer></v-spacer>
            <v-btn @click="refresh_arbitrage_strategy" color="secondary">
                Restablecer
            </v-btn>
            <v-btn @click="patch_arbitrage_strategy" color="success" class="mx-2">
                Guardar
            </v-btn>
        </v-row>
        <br>
        <br>
    </div>
</template>

<script>
import coins from "@/utils/coins"
import FieldPercentage from "@/components/FieldPercentage"
export default {
    components: { FieldPercentage },
    data: () => ({
        base_coins_list: coins.base_coins_list,
        quote_coins_list: coins.quote_coins_list,
        risk_per_operation: 0,
        base_coins: "",
        quote_coins: "",
        bb_window: 20,
        bb_window_dev: 2,
        bullish_gain: 0.0012,
        stoch_window: 14,
        stoch_smooth1: 3,
        stoch_smooth2: 3,
        stoch_lower_limit: 25,
        stoch_cross_validation_limit: 35,
        stoch_upper_limit: 95,
        stoch_upper_limit_sell: 80,
        threshold_24h_gain: 0,
        stop_loss_a: -0.015,
        stop_loss_b: -0.005,
        min_profit_high: 0.0017,
        min_profit_low: 0.0017,
        active: false,
        exchange: 1,







    }),
    computed: {
        strategy() {
            return this.$store.state.bot.strategies.find(
                (st) => st.strategy == "bbs"
            )
        },
    },
    watch: {
        strategy() {
            this.fillForm()
        },
    },
    methods: {
        clean_active() {
            const st = {
                ...this.strategy,
                active_symbols: [],
            }
            this.$store.dispatch("bot/patchStrategy", st)
        },
        clean_bl() {
            const st = {
                ...this.strategy,
                black_list: [],
            }
            this.$store.dispatch("bot/patchStrategy", st)
        },
        patch_arbitrage_strategy() {
            let st = {
                ...this.strategy,
                params: {
                    base_coins: this.base_coins,
                    quote_coins: this.quote_coins,
                    bb_window: this.bb_window,
                    bb_window_dev: this.bb_window_dev,
                    bullish_gain: this.bullish_gain,
                    stoch_window: this.stoch_window,
                    stoch_smooth1: this.stoch_smooth1,
                    stoch_smooth2: this.stoch_smooth2,
                    stoch_lower_limit: this.stoch_lower_limit,
                    stoch_cross_validation_limit: this.stoch_cross_validation_limit,
                    stoch_upper_limit: this.stoch_upper_limit,
                    stoch_upper_limit_sell: this.stoch_upper_limit_sell,
                    threshold_24h_gain: this.threshold_24h_gain,
                    stop_loss_a: this.stop_loss_a,
                    stop_loss_b: this.stop_loss_b,
                    min_profit_high: this.min_profit_high,
                    min_profit_low: this.min_profit_low,
                },
                risk_per_operation: this.risk_per_operation,
                active: this.active,
                exchange: this.exchange,
            }
            delete st["active_symbols"]
            delete st["black_list"]
            this.$store.dispatch("bot/patchStrategy", st)
        },
        refresh_arbitrage_strategy() {
            this.$store
                .dispatch("bot/loadStrategies")
                .then(() => this.fillForm())
        },
        fillForm() {
            if (this.strategy) {
                // Strategy JSON dict params
                this.base_coins = this.strategy.params.base_coins
                this.quote_coins = this.strategy.params.quote_coins
                this.bb_window = this.strategy.params.bb_window
                this.bb_window_dev = this.strategy.params.bb_window_dev
                this.bullish_gain = this.strategy.params.bullish_gain
                this.stoch_window = this.strategy.params.stoch_window
                this.stoch_smooth1 = this.strategy.params.stoch_smooth1
                this.stoch_smooth2 = this.strategy.params.stoch_smooth2
                this.stoch_lower_limit = this.strategy.params.stoch_lower_limit
                this.stoch_cross_validation_limit = this.strategy.params.stoch_cross_validation_limit
                this.stoch_upper_limit = this.strategy.params.stoch_upper_limit
                this.stoch_upper_limit_sell = this.strategy.params.stoch_upper_limit_sell
                this.threshold_24h_gain = this.strategy.params.threshold_24h_gain
                this.stop_loss_a = this.strategy.params.stop_loss_a
                this.stop_loss_b = this.strategy.params.stop_loss_b
                this.min_profit_high = this.strategy.params.min_profit_high
                this.min_profit_low = this.strategy.params.min_profit_low

                // native strategy fields
                this.risk_per_operation = this.strategy.risk_per_operation
                this.active = this.strategy.active
                this.exchange = this.strategy.exchange
            }
        },
    },
    mounted() {
        this.fillForm()
    },
}
</script>
