<template>
    <div class="fill-height pa-4">
        <h1>Configuración</h1>
        <h3 class="mt-3">API Binance</h3>
        <form autocomplete="off">
            <v-text-field :append-icon="show_api_key ? 'mdi-eye' : 'mdi-eye-off'" class="mr-2 mt-3"
                :type="show_api_key ? 'text' : 'password'" label="API KEY" outlined v-model="api_key"
                @click:append="show_api_key = !show_api_key"></v-text-field>
            <v-text-field :append-icon="show_secret_key ? 'mdi-eye' : 'mdi-eye-off'" class="mr-2"
                :type="show_secret_key ? 'text' : 'password'" label="SECRET KEY" outlined v-model="secret_key"
                @click:append="show_secret_key = !show_secret_key"></v-text-field>
            <v-row class="mx-0">
                <v-spacer></v-spacer>
                <v-btn @click="refresh" color="secondary">Restablecer</v-btn>
                <v-btn @click="save" color="success" class="mx-2">Guardar</v-btn>
            </v-row>
        </form>
    </div>
</template>

<script>
export default {
    name: "Settings",
    components: {},
    data: () => ({
        show_api_key: false,
        show_secret_key: false,
        api_key: "",
        secret_key: "",
    }),
    computed: {
        binance_auth() {
            return this.$store.state.bot.exchange_auths.find(() => true);
        },
    },
    watch: {
        binance_auth(ea) {
            this.api_key = ea.api_key;
            this.secret_key = ea.secret_key;
        },
    },
    methods: {
        refresh() {
            this.$store.dispatch("bot/loadExchangeAuths");
        },
        save() {
            let ea = {
                ...this.binance_auth,
                api_key: this.api_key,
                secret_key: this.secret_key,
            };
            this.$store.dispatch("bot/patchExchangeAuth", ea);
        },
    },
    mounted() {
        this.refresh();
    },
};
</script>
