import { backend_api } from '@/utils/api'
import bus from '@/utils/event_bus'
// import vue from '@/main'

export default {
    namespaced: true,
    state: {
        access_token: "",
        refresh_token: "",
        authenticated: false,
        user_object: {
            username: "",
            wallet: {}
        }
    },
    mutations: {
        setTokens(state, data) {
            state.access_token = data.access
            state.refresh_token = data.refresh
            state.authenticated = true
            localStorage.setItem('access_token', state.access_token)
            localStorage.setItem('refresh_token', state.refresh_token)
        },
        refreshToken(state, at) {
            state.access_token = at
            localStorage.setItem('access_token', state.access_token)
        },
        cleanSession(state) {
            state.access_token = ""
            state.refresh_token = ""
            state.authenticated = false
            localStorage.setItem('access_token', "")
            localStorage.setItem('refresh_token', "")
        },
        setUserObject(state, data) {
            state.user_object = data
        }
    },
    actions: {
        login(context, data) {
            return backend_api.post('/token/', data)
                .then(({ data }) => {
                    console.log("LOGIN...")
                    console.log(data)
                    context.commit('setTokens', data)
                    bus.$emit("notification", {
                        timeout: 1000,
                        text: "Login completo.",
                        color: "success"
                    });
                    context.dispatch('loadAll', null, { root: true })
                    return data
                })
                .catch((e) => {
                    console.log("ERROR...")
                    if (e.response && e.response.status == 401) {
                        bus.$emit("notification", {
                            timeout: 1000,
                            text: "Credenciales incorrectas.",
                            color: "warning"
                        });
                        // bus.$emit("launch_login")
                        throw e
                    } else {
                        bus.$emit("notification", {
                            timeout: 3000,
                            text: "No se puede loguear. Intenta de nuevo o contacta al admin.",
                            color: "error"
                        });
                        throw e
                    }
                })
        },
        refresh(context) {
            return backend_api.post('/token/refresh/', { refresh: context.state.refresh_token })
                .then(({ data }) => {
                    context.commit('refreshToken', data.access)
                    context.dispatch('loadAll', null, { root: true })
                })
                .catch(() => {
                    bus.$emit("notification", {
                        timeout: 1000,
                        text: "Failed to refresh session. You have to login.",
                        color: "error"
                    });
                    // bus.$emit("launch_login")
                    context.commit('cleanSession')
                })
        },
        getUserObject(context) {
            return backend_api.get('/my_user/')
                .then(({ data }) => {
                    context.commit('setUserObject', data)
                })
                .catch(e => console.log(`Cant get User Object: ${e}`))
        }
    },
    getters: {
    }
}