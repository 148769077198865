<template>
    <div class="fill-height pa-4">
        <h1>Tutorials</h1>
        <div class="d-flex flex-wrap">
            <v-card :href="tutorial.asset" target="_blank" width="500" class="ma-3" v-for="tutorial in tutorials"
                :key="tutorial.id">
                <v-img :src="require('@/assets/' + tutorial.picture)" aspect-ratio="0.8"></v-img>
                <v-card-text> {{ tutorial.text }} </v-card-text>
                <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :href="tutorial.asset" target="_blank">OPEN</v-btn>
                </v-card-actions> -->
            </v-card>
        </div>
    </div>
</template>

<script>
export default {
    name: "Tutorials",
    components: {},
    data: () => ({
        tutorials: [
            { "id": 1, "text": "Tutorial AI Crypto Bot - Español", "picture": "tuto_es_mini.png", "asset": "/static/pdfs/tuto_es.pdf" },
            { "id": 2, "text": "AI Crypto Bot Tutorial - English", "picture": "tuto_en_mini.png", "asset": "/static/pdfs/tuto_en.pdf" }
        ]
    }),
};
</script>
