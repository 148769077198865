import * as ccxt from "ccxt"
import _ from 'lodash';
import store from '@/store'


function triangles(ex, bases, quotes) {

    // Returns a list of objects representing each triangular oportunity.

    //      BASE
    //       /\
    //  bq0 /  \  bq1
    //     /    \
    //     ------
    //     QUOTE0  q0q1 QUOTE1

    // Object example:
    // {
    //     'name': "BTC>XMR>USDT>BTC",
    //     'base': 'XMR',
    //     'q0': 'BTC',
    //     'q1': 'USDT',
    //     'bq0': 'XMR/BTC',
    //     'bq1': 'XMR/USDT',
    //     'q0q1': 'BTC/USDT',
    // }


    let triangles = []

    // NEW ALGO
    let quote_perms = _.permutations(quotes, 2)
    let product = _.product(bases, quote_perms)
    product.forEach(pr => {
        let b = pr[0]
        let qs = pr[1]
        let bq0 = b + "/" + qs[0]
        let bq1 = b + "/" + qs[1]
        let q0q1 = qs[0] + "/" + qs[1]
        let triangle = {
            'name': qs[0] + " > " + b + " > " + qs[1] + " > " + qs[0],
            'base': b,
            'q0': qs[0],
            'q1': qs[1],
            'bq0': bq0,
            'bq1': bq1,
            'q0q1': q0q1,
        }
        // if bq0 in markets and bq1 in markets and q0q1 in markets:
        triangles.push(triangle)
    })
    return triangles
}

function get_ex(name) {
    // {
    //     'apiKey': YOUR_API_KEY,
    //         'secret': 'YOUR_SECRET',
    //             'enableRateLimit': true,
    //                 'options': {
    //         'createMarketBuyOrderRequiresPrice': false, // switch off
    // },
    // }
    return new ccxt[name]({
        enableRateLimit: true
    })
}

function numf(num) {
    return num
}

function get_cost_from_btc_risk(btc_risk, cost_coin, bid_asks) {
    if (cost_coin == "BTC") return btc_risk

    let symbol = cost_coin + "/BTC"
    console.log("[i] Symbol for cost calculation: ", symbol)
    let ticker_info = bid_asks[symbol]
    if (ticker_info) {
        let last_price = ticker_info.ask
        return Number(btc_risk) / Number(last_price)
    } else {
        symbol = "BTC/" + cost_coin
        console.log("[i] Inverse symbol for cost calculation: ", symbol)
        let ticker_info = bid_asks[symbol]
        if (ticker_info) {
            let last_price = ticker_info.bid
            return Number(btc_risk) * Number(last_price)
        }
        else {
            return 0
        }
    }


    // 0.1 Bitcoins de riesgo.
    // Eth ?
    // eth / btc = 0.05
    // eth debería ser 2.
    // btc / eth = 20
    // eth deberia ser 2

}


function format_amount_and_price(amount, price, cost, market) {

    // limits = {
    //     'amount': { 'min': 0.001, 'max': 10000000.0 },
    //     'price': { 'min': 1e-06, 'max': 1000.0 },
    //     'cost': { 'min': 0.0001, 'max': None },
    //     'market': { 'min': 0.0, 'max': 10370.50932755 }
    // }

    if (!market)
        throw "Market data not provided. Can't format"

    if (price == 0)
        throw "This is trading at ZERO PRICE!"

    if (amount && price) {
        cost = Number(amount) * Number(price)
    }
    else if (price && cost) {
        amount = Number(cost) / Number(price)
    }

    let price_precision = market["precision"]["price"]
    let amount_precision = market["precision"]["amount"]
    let amount_min = market["limits"]["amount"]["min"]
    let cost_min = market["limits"]["cost"]["min"]

    let f_amount = _.floor(amount, amount_precision)
    let f_price = _.round(price, price_precision)

    if (Number(f_amount) < Number(amount_min))
        throw `Amount too low: ${f_amount}, ${market["symbol"]}`

    if (cost < cost_min)
        throw `Cost too low: ${cost}, ${market["symbol"]}`

    // console.log(`amount: ${f_amount}, cost: ${cost}, price: ${f_price}`)

    return [f_amount, f_price, cost]


}

async function crafted_market_order(ex, symbol, side, amount, price) {
    let mkt = ex.markets[symbol]
    let formats = format_amount_and_price(amount, price, null, mkt)
    let f_amount = formats[0]
    if (!side) throw "Sentido del trade desconocido: " + side
    console.log("BEFORE CREATE ORDER", symbol, 'market', side.toLowerCase(), f_amount)
    return await ex.createOrder(symbol, 'market', side.toLowerCase(), f_amount)
}

async function execute_op(ex, bid_asks, op, risk) {

    let amount1, amount2, amount3
    let cost1, cost2, cost3
    let in_quantity = 0
    let out_quantity = 0

    if (op.side1 == "BUY") {
        amount1 = get_cost_from_btc_risk(risk, op.base, bid_asks)
        cost1 = op.price1 * amount1
        op.q0_amount = cost1
        op.b_amount = amount1
    } else {
        amount1 = get_cost_from_btc_risk(risk, op.q0, bid_asks)
        cost1 = op.price1 * amount1
        op.q0_amount = amount1
        op.b_amount = cost1
    }
    console.log(`[i] ORDER 1. ${op.symbol1} am ${amount1} cst ${cost1}`)
    let order1 = await crafted_market_order(ex, op.symbol1, op.side1, amount1, op.price1)

    // THIS FOR APARENT ROI
    if (op.side1 == "BUY") in_quantity = order1.cost
    else in_quantity = order1.amount

    if (op.side1 == "BUY" && op.side2 == "SELL") {
        amount2 = order1.amount || 0
        cost2 = op.price2 * amount2
    } else if (op.side1 == "BUY" && op.side2 == "BUY") {
        cost2 = order1.amount || 0
        amount2 = cost2 / op.price2
    } else if (op.side1 == "SELL" && op.side2 == "SELL") {
        amount2 = order1.cost || 0
        cost2 = op.price2 * amount2
    } else if (op.side1 == "SELL" && op.side2 == "BUY") {
        cost2 = order1.cost || 0
        amount2 = cost2 / op.price2
    }

    // if (op.side2 == "BUY") {
    //     op.q1_amount = op.b_amount / op.price2
    //     amount2 = op.q1_amount
    //     cost2 = op.b_amount
    // } else {
    //     op.q1_amount = op.b_amount * op.price2
    //     amount2 = op.b_amount
    //     cost2 = op.q1_amount
    // }
    console.log(`[i] ORDER 2. ${op.symbol2} am ${amount2} cst ${cost2}`)
    let order2 = await crafted_market_order(ex, op.symbol2, op.side2, amount2, op.price2)


    if (op.side2 == "BUY" && op.side3 == "SELL") {
        amount3 = order2.amount || 0
        cost3 = op.price3 * amount3
    } else if (op.side2 == "BUY" && op.side3 == "BUY") {
        cost3 = order2.amount || 0
        amount3 = cost3 / op.price3
    } else if (op.side2 == "SELL" && op.side3 == "SELL") {
        amount3 = order2.cost || 0
        cost3 = op.price3 * amount3
    } else if (op.side2 == "SELL" && op.side3 == "BUY") {
        cost3 = order2.cost || 0
        amount3 = cost3 / op.price3
    }

    // if (op.side3 == "BUY") {
    //     amount3 = op.q1_amount / op.price3
    //     cost3 = op.q1_amount
    // } else {
    //     amount3 = op.q1_amount
    //     cost3 = op.q1_amount * op.price3
    // }

    let order3 = await crafted_market_order(ex, op.symbol3, op.side3, amount3, op.price3)
    console.log(`[i] ORDER 3. ${op.symbol3} am ${amount3} cst ${cost3}`)
    console.log("[!] Operacion ejecutada!", order1, order2, order3);


    // THIS FOR APARENT ROI
    if (op.side3 == "BUY") out_quantity = order1.amount
    else out_quantity = order1.cost

    let aparent_roi = out_quantity / in_quantity

    // Save order to registry
    store.dispatch("bot/postOperation", {
        status: "completed",
        strategy: null,
        metadata: { ...op, roi: op.profit, aparent_roi },
        modified: new Date(),
        created: new Date(),
    })
}

export default {
    triangles,
    get_ex,
    numf,
    execute_op,
    format_amount_and_price,
}