<template>
    <div class="fill-height pa-4">
        <v-data-table width="100%" :headers="headers" :items="operations" :items-per-page="items_page"
            class="elevation-1" sort-by="modified" sort-desc>
            <template v-slot:item.uuid="{ item }">
                {{ item.uuid.substring(0, 8) }}
            </template>
            <template v-slot:item.side="{ item }">
                <v-chip v-if="item.metadata.side == 'sell'" outlined color="red">
                    VENTA
                </v-chip>
                <v-chip v-else outlined color="green"> COMPRA </v-chip>
            </template>
            <template v-slot:item.tweaked_to="{ item }">
                {{ item.metadata.tweaked_to }}
            </template>

            <template v-slot:item.metadata="{ item }">
                <template v-for="(v, k) in item.metadata">
                    <span class="caption ma-0 pa-0 font-weight-thin" v-if="v &&
            ![
                'symbol',
                'side',
                'rsi',
                'q',
                'b',
                'tweaked_to',
                'internal_status',
            ].includes(k)
            " :key="k">
                        {{ k }}: {{ v }} <br />
                    </span>
                </template>
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    name: "History",
    components: {},
    data: () => ({
        items_page: 15,
        headers: [
            { text: "UUID", align: "start", sortable: false, value: "uuid" },
            { text: "Lado", value: "side" },
            { text: "Par", value: "metadata.symbol" },
            { text: "Ultima orden", value: "tweaked_to" },
            { text: "Metadata", value: "metadata" },
            { text: "Status", value: "status" },
            { text: "Apertura", value: "created" },
            { text: "Cierre", value: "modified" },
            { text: "Exchange", value: "exchange" },
            // { text: "ROI %", value: "roi" },
        ],
    }),
    computed: {
        operations() {
            return this.$store.state.bot.all_operations.map((o) => ({
                ...o,
                exchange: "Binance",
                // roi: Math.round(o.metadata.roi * 10000) / 100,
                // roi_quote: o.metadata?.roi_quote,
                meta_parsed: this.parse_metadata(o.metadata),
                created: o.created.substring(0, 19).replace("T", " "),
                modified: o.modified.substring(0, 19).replace("T", " "),
            }));
        },
    },
    methods: {
        parse_metadata(meta) {
            let items = [];
            Object.keys(meta).forEach((k) => {
                items.push(k + ": " + meta[k]);
            });
            return items.join(", ");
        },
    },
    mounted() {
        this.$store.dispatch("bot/loadAllOperations");
    },
};
</script>
