<template>
    <v-container class="noti">
        <v-layout column>
            <v-flex width="200">
                <v-alert
                    v-for="not in notifications"
                    :key="not.uuid"
                    :value="true"
                    :type="not.color||'info'"
                    @click="destroy(not)"
                    bottom
                    right
                >{{not.text}}</v-alert>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<style lang="scss" scoped>
.noti {
    width: 400px;
    position: fixed;
    bottom: 0px;
    right: 0px;
}
</style>

<script>
// EMIT NOTIFICATIONS LIKE THIS
// import bus from '@/utils/event_bus'
// bus.$emit('notification', {timeout: 5000, text:"Testing notifications!", color:"success"})
import bus from "@/utils/event_bus";
export default {
    name: "snackbar",
    data: () => ({
        show: true,
        notifications: [],
    }),
    computed: {},
    methods: {
        destroy(index) {
            this.notifications.splice(index, 1);
        },
        test_not() {
            this.notificate({
                text: "Testing notifications!",
                color: "error",
                timeout: 5000,
            });
        },
        notificate(n) {
            let duplicate = false;
            this.notifications.forEach((on) => {
                if (n.text == on.text && n.color == on.color) {
                    duplicate = true;
                }
            });
            if (!duplicate) {
                this.notifications.push({ ...n, uuid: Math.random() });
                setTimeout(() => this.destroy(n), n.timeout ? n.timeout : 6000);
            }
        },
    },
    mounted() {
        bus.$on("notification", (n) => this.notificate(n));
    },
};
</script>