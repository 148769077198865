<template>
    <div class="fill-height pa-4">
        <h1>Estrategia: Arbitraje Triangular</h1>
        <br />
        <v-checkbox v-model="active" label="Activar Arbitraje Triangular"></v-checkbox>
        <v-select :disabled="!active" :items="base_coins_list" label="Monedas Base" placeholder="ADA, XMR, LMN" outlined
            multiple v-model="base_coins"></v-select>
        <div class="d-flex">
            <v-select :disabled="!active" :items="quote_coins_list" class="mr-2" label="Monedas Quote"
                placeholder="BTC, ETH, USDT, BNB" outlined multiple v-model="quote_coins"></v-select>
            <v-text-field :disabled="!active" class="mr-2" label="Riesgo por operacion" placeholder="0.01" suffix="BTC"
                outlined v-model="risk_per_operation"></v-text-field>
            <v-select label="Exchange" v-model="exchange" :items="[{ text: 'Binance', value: 1 }]" outlined></v-select>
        </div>
        <v-text-field :disabled="!active" label="Beneficio mínimo" placeholder="0.005" suffix="Beneficio/Capital" outlined
            v-model="min_profit"></v-text-field>
        <v-row class="mx-0">
            <v-spacer></v-spacer>
            <v-btn @click="refresh_arbitrage_strategy" color="secondary">Restablecer</v-btn>
            <v-btn @click="patch_arbitrage_strategy" color="success" class="mx-2">Guardar</v-btn>
        </v-row>
    </div>
</template>

<script>
import coins from "@/utils/coins";
export default {
    components: {},
    data: () => ({
        base_coins_list: coins.base_coins_list,
        quote_coins_list: coins.quote_coins_list,

        base_coins: "",
        quote_coins: "",
        risk_per_operation: 0,
        min_profit: 0,
        active: false,
        exchange: 1,
    }),
    computed: {
        strategy() {
            return this.$store.state.bot.strategies.find(
                (st) => st.strategy == "triangular"
            );
        },
    },
    watch: {
        strategy() {
            this.fillForm();
        },
    },
    methods: {
        patch_arbitrage_strategy() {
            let st = {
                ...this.strategy,
                params: {
                    base_coins: this.base_coins,
                    quote_coins: this.quote_coins,
                },
                risk_per_operation: this.risk_per_operation,
                min_profit: this.min_profit,
                active: this.active,
                exchange: this.exchange,
            };
            this.$store.dispatch("bot/patchStrategy", st);
        },
        refresh_arbitrage_strategy() {
            this.$store
                .dispatch("bot/loadStrategies")
                .then(() => this.fillForm());
        },
        fillForm() {
            if (this.strategy) {
                this.base_coins = this.strategy.params.base_coins;
                this.quote_coins = this.strategy.params.quote_coins;
                this.risk_per_operation = this.strategy.risk_per_operation;
                this.min_profit = this.strategy.min_profit;
                this.active = this.strategy.active;
                this.exchange = this.strategy.exchange;
            }
        },
    },
    mounted() {
        this.fillForm();
    },
};
</script>
