<template>
    <v-card outlined class="ma-1" width="500">
        <v-card-text>
            <v-chip small outlined class="mx-1" v-for="chip in op_chips" :key="chip.id" :color="chip.color">{{ chip.text }}
            </v-chip>
        </v-card-text>

        <v-card-title v-if="is_rsi_wait">
            {{ operation.metadata.symbol }}
        </v-card-title>

        <v-card-title v-else-if="is_bbs">
            {{ operation.metadata.symbol }} (MODO {{ operation.metadata.mode }})
        </v-card-title>

        <v-card-title v-else>
            {{ operation.metadata.q0 }}
            -> {{ operation.metadata.base }} -> {{ operation.metadata.q1 }} ->
            {{ operation.metadata.q0 }}
        </v-card-title>

        <v-card-text>
            Ultimo update: {{ parseBackendDateTime(operation.modified) }} <br />
            UUID: {{ operation.uuid }} <br />
            Exchange: BINANCE, Estrategia:
            {{ strategy_name }} <br />
        </v-card-text>

        <v-card-actions v-if="is_rsi_wait || is_bbs">
            <div class="my-4" />
            <v-spacer></v-spacer>
            <ButtonDialogForceClose :operation="operation" />
            <v-btn :disabled="closing" :loading="closing" v-if="operation.status == 'open'" @click="market_close" small
                outlined color="warning">Cerrar operacion</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import ButtonDialogForceClose from "@/components/ButtonDialogForceClose";
export default {
    props: ["operation"],
    components: {
        ButtonDialogForceClose,
    },
    data: () => ({ closing: false }),
    computed: {
        rsi_strategy() {
            return this.$store.state.bot.strategies.find(
                (st) => st.strategy == "rsi_wait"
            );
        },
        bbs_strategy() {
            return this.$store.state.bot.strategies.find(
                (st) => st.strategy == "bbs"
            );
        },
        is_rsi_wait() {
            return this.rsi_strategy.id == this.operation.strategy;
        },
        is_bbs() {
            return this.bbs_strategy.id == this.operation.strategy;
        },
        strategy() {
            return this.$store.state.bot.strategies.find(st => st.id == this.operation.strategy)
        },
        strategy_name() {
            return this.strategy.strategy
        },
        op_chips() {
            let chips = [];
            if (this.operation.status == "open") {
                chips.push({ color: "info", text: "En curso" });
            } else if (this.operation.status == "canceled") {
                chips.push({ color: "warning", text: "Cancelada" });
            } else if (
                ["error_retry", "error"].includes(this.operation.status)
            ) {
                chips.push({ color: "error", text: "Error" });
            } else if (
                ["no_funds_retry", "no_funds"].includes(this.operation.status)
            ) {
                chips.push({ color: "error", text: "Fondos insuficientes" });
            } else if (this.operation.status == "to_close") {
                chips.push({ color: "warning", text: "Cerrando" });
            } else {
                chips.push({ color: "", text: "Cerrada" });
            }
            if (this.operation.metadata.roi) {
                chips.push({
                    color:
                        this.operation.metadata.roi >= 0 ? "success" : "error",
                    text: `ROI: ${Math.round(this.operation.metadata.roi * 10000) / 100
                        }%`,
                });
            }
            return chips;
        },
    },
    methods: {
        market_close() {
            let payload = { ...this.operation, status: "to_close" };
            this.closing = true;
            this.$store
                .dispatch("bot/patchOperation", payload)
                .finally(() => (this.closing = false));
        },
        parseBackendDateTime(dt) {
            return dt.split("T")[0] + " " + dt.split("T")[1].substring(0, 5);
        },
    },
};
</script>
