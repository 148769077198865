<template>
    <div class="fill-height pa-4">
        <h1>Operaciones</h1>
        <div class="d-flex flex-wrap" v-if="operations.length != 0">
            <v-lazy
                :options="{ threshold: 0.5 }"
                min-height="200"
                transition="fade-transition"
                v-for="op in operations"
                :key="op.uuid"
            >
                <OperationItem :operation="op" />
            </v-lazy>
        </div>

        <div class="d-flex flex-column align-center" v-else>
            <v-icon size="120" color="grey">mdi-bank-transfer</v-icon>
            <div class="display-1 mb-3">No hay operaciones</div>
            <div class="subheading mb-3">
                No hay operaciones. Espera mientras se cargan o a que el bot
                encuentre oportunidades.
            </div>
        </div>
    </div>
</template>

<script>
import OperationItem from "@/components/OperationItem";
export default {
    name: "Operations",
    components: {
        OperationItem,
    },
    data: () => ({}),
    computed: {
        operations() {
            return this.$store.state.bot.operations;
        },
    },
    methods: {
        op_chips(op) {
            let chips = [];
            if (op.status == "open") {
                chips.push({ color: "info", text: "En curso" });
            } else if (op.status == "canceled") {
                chips.push({ color: "warning", text: "Cancelada" });
            } else if (["error_retry", "error"].includes(op.status)) {
                chips.push({ color: "error", text: "Error" });
            } else if (["no_funds_retry", "no_funds"].includes(op.status)) {
                chips.push({ color: "error", text: "Fondos insuficientes" });
            } else {
                chips.push({ color: "", text: "Cerrada" });
            }
            if (op.metadata.roi) {
                chips.push({
                    color: op.metadata.roi >= 0 ? "success" : "error",
                    text: `ROI: ${Math.round(op.metadata.roi * 10000) / 100}%`,
                });
            }
            return chips;
        },
    },
};
</script>
