var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fill-height pa-4"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"width":"100%","headers":_vm.headers,"items":_vm.operations,"items-per-page":_vm.items_page,"sort-by":"modified","sort-desc":""},scopedSlots:_vm._u([{key:"item.uuid",fn:function({ item }){return [_vm._v(" "+_vm._s(item.uuid.substring(0, 8))+" ")]}},{key:"item.side",fn:function({ item }){return [(item.metadata.side == 'sell')?_c('v-chip',{attrs:{"outlined":"","color":"red"}},[_vm._v(" VENTA ")]):_c('v-chip',{attrs:{"outlined":"","color":"green"}},[_vm._v(" COMPRA ")])]}},{key:"item.tweaked_to",fn:function({ item }){return [_vm._v(" "+_vm._s(item.metadata.tweaked_to)+" ")]}},{key:"item.metadata",fn:function({ item }){return [_vm._l((item.metadata),function(v,k){return [(v &&
        ![
            'symbol',
            'side',
            'rsi',
            'q',
            'b',
            'tweaked_to',
            'internal_status',
        ].includes(k)
        )?_c('span',{key:k,staticClass:"caption ma-0 pa-0 font-weight-thin"},[_vm._v(" "+_vm._s(k)+": "+_vm._s(v)+" "),_c('br')]):_vm._e()]})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }